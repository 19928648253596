@import 'components.scss';

.App{
    min-height: 100vh;
    background-color: #fff;
}

.loader-background{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: #8d8d8d71;
    display: flex;
    align-items: baseline;
}

h1 {
    font-size: 22px;
}

p {
    font-size: 13px;
}

ul {
    padding: 0;
    margin: 0 !important;
}

.containerFull {
    max-width: 95%;
    margin: auto;
}

header {
    padding: 16px 20px;
    display: flex;
    align-items: baseline;
    border-bottom: solid 1px #ddd;
    background-color: #EAEAEA;

    nav {
        margin-top: 6px;
    }

    svg {
        margin-right: 8px;
    }
}

.title {
    font-size: 24px;
}

.title-intern {
    font-size: 20px;
    font-weight: 500;

    svg {
        margin-right: 10px;
    }
}

.nav-right {
    text-align: right;

    li {
        display: inline-flex;
        margin-right: 15px;
    }
}


.nav-default {

    li {
        display: inline-flex;
        margin: 0px 15px;
    }
    a {
        color: #333;
    }
}

.NavMenuIntern {
    li {
        display: inline-flex;
        margin: 0px 12px;       

        a {
            color: #333;
            border: solid 1px #ddd;
            border-radius: 6px;
            padding: 12px 25px;
            display: block;
            background: #FFF;
            text-decoration: none;
            transition: 0.5s;

            &:hover {
                background: #ccc;
                border: solid 1px transparent;
            }
        }

        svg {
            width: 25px;
            margin-right: 10px;
        }
    }
}

.circle {
    width: 70px;
    height: 70px;
    display: inline-block;
    background-color: #ccc;
    border-radius: 50%;
}

.hold-circle {
    position: relative;
    top: 25px;
    margin-right: 20px;
}

.boxDefault {
    min-height: 185px;
    margin-top: 25px;
    padding: 20px;
    background: #FFF;
    border-radius: 8px;
    border: solid 1px #ddd;
    transition: 0.5s;

    h2 {
        font-size: 18px;

        svg {
            margin-right: 12px;
        }
    }

    a {
        position: absolute;
        bottom: 15px;
        font-size: 18px;
        font-weight: 600;

        svg {
            margin-right: 13px;
        }
    }

    &:hover {
        background-color: #ccc;
    }
}

.boxDefaultSmall {
    min-height: 105px;
    margin-top: 25px;
    padding: 20px;
    background: #FFF;
    border-radius: 8px;
    border: solid 1px #ddd;
    transition: 0.5s;

    h2 {
        font-size: 18px;

        svg {
            margin-right: 12px;
        }
    }

    a {
        position: absolute;
        bottom: 15px;
        font-size: 18px;
        font-weight: 600;

        svg {
            margin-right: 13px;
        }
    }

    &:hover {
        background-color: #ccc;
    }
}


