.infoUser {    
    padding-top: 15px;

    .boxUser {
        min-height: 135px;
        padding: 30px 0px 0px 18px;        
        
        h2 {
            font-size: 25px;
            padding-top: 12pxs;
        }

        svg {
            margin-right: 7px;
        }
    }    
}