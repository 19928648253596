.CircularProgressbar  {
    width: 100px !important;
    .CircularProgressbar-path {
        stroke: rgb(20, 121, 20) !important;
    } 

} 

.spNew {
    border: solid 1px #007bff;
    color: #007bff;
    padding: 8px 12px;
    border-radius: 18px;
}