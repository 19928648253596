.MainBox {
    margin-bottom: 50px;

    .nowModules {
        padding-top: 30px;

        a {
            border: solid 1px #D7D5D5;
            border-radius: 6px;
            display: block;
            padding: 10px 30px;
            text-align: center;
            color: #525252;
            background: #FFF;
            transition: 0.5s;
            text-decoration: none;

            &:hover {
                border: solid 1px #0F7EAA;
                color: #0F7EAA;
            }

            svg {
                margin-right: 17px;                
            }
        }
    }
}